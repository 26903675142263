<template>
  <div class="container-fluid pt-3">
    <div class="d-flex flex-column justify-content-center align-items-lg-center">
      <div>
        <h1 class="mb-3">Impressum</h1>
        <p>
          <strong>F-Cat Productions GmbH</strong><br />
          Pohlstraße 39<br />
          10785 Berlin<br />
        </p>
        <p>
          Tel.: +49-(0)30 – 26 103 29 –10<br />
          E-Mail: <a href="mailto:info@f-cat.de">info@f-cat.de</a><br />
          Web: <a href="https://f-cat.de">f-cat.de</a>
        </p>
        <p>
          <strong>Programmierung &amp; Gestaltung</strong><br />
          <a href="https://grandgeorg.de" target="_blank" style="text-decoration: none">
            <strong>
              <span style="color: #f30">Grandgeorg </span>
              <span style="color: #36c">Websolutions</span>
            </strong> </a
          ><br />
          Viktor Grandgeorg<br />
        </p>
      </div>
    </div>
  </div>
</template>
